import styled, { keyframes } from 'styled-components';
import { Props } from './types';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.span<Props>`
  display: flex;
  width: ${(props) => props.size || 25}px;
  height: ${(props) => props.size || 25}px;
  animation-duration: 1.4s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: ${rotate};


  svg {
    circle {
      animation-duration: 1.4s;
      animation-timing-function: ease-in-out;
      animation-delay: 0s;
      animation-iteration-count: infinite;
      animation-direction: normal;
      animation-fill-mode: none;
      animation-play-state: running;
      stroke-dasharray: 80px, 200px;
      stroke-dashoffset: 0px;
      stroke: ${(props) => props.color || props.theme.colors.primary};
      }
  }

`;
