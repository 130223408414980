import styled from 'styled-components';
import Lottie from 'react-lottie';
import closeModal from '../../assets/icons/close-modal.svg';

export const Container = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 22;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.7s ease-in-out;
  transform: ${({ show }) => (show ? 'translateY(0)' : 'translateY(-100%)')};
  @media (max-width: 460px) {
    padding: 0 20px;
  }
`;

export const CloseModal = styled.img.attrs({
  src: closeModal,
})`
  position: absolute;
  left: calc(100% - 40px);
  top: 20px;
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

export const Modal = styled.div<{ show: boolean }>`
  position: relative;
  max-width: 600px;
  min-width: 300px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  padding: 40px;
`;

export const Title = styled.h2`
  margin: 20px 0;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  text-align: center;
`;

export const Text = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.lightGray};
  font-weight: 400;
  max-width: 350px;
  text-align: center;
  line-height: 18px;
  margin-bottom: 20px;
`;

export const SentEmailAnimation = styled(Lottie)``;
