import React from 'react';

import PublicRoutes from './public-routes';
import PrivateRoutes from './private-routes';
import { useAuth } from '../hooks/AuthContext';
import {
  getEc,
  getUser,
  setEc,
  setUser,
} from '../services/user';
import api from '../services/api';

const Routes: React.FC = () => {
  const {
    allowQ2Link,
    authenticated,
    setAllowQ2Link,
    loading,
    ecListState,
  } = useAuth();

  if (loading) {
    return <></>;
  }

  if (authenticated && ecListState.length === 1) {
    setEc(ecListState[0]);
  }

  if (allowQ2Link < 1 && authenticated) {
    const ec = getEc();
    const getLinkStatus = () => {
      api.get('/checkq2linkstatus', {
        params: {
          ecId: ec.Id,
        },
      }).then((res) => {
        const { q2LinkStatus, requestId } = res.data;
        setAllowQ2Link(q2LinkStatus);
        if (requestId) {
          setUser({
            ...getUser(),
            requestId,
          });
        }
      });
    };

    getLinkStatus();
  }

  return authenticated ? <PrivateRoutes /> : <PublicRoutes />;
};

export default Routes;
