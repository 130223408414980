import React from 'react';
import { Container } from './styles';
import { Props } from './types';

const LoadingSpinner: React.FC<Props> = ({ size, color }) => (
  <Container size={size} color={color}>
    <svg viewBox="22 22 44 44">
      <circle
        cx="44"
        cy="44"
        r="20.2"
        fill="none"
        strokeWidth="3.6"
      />
    </svg>
  </Container>
);

export default LoadingSpinner;
