import { lumiance } from './lumiance';
import {
  APP_TOKEN,
  Months,
  WeekDaysShort,
  WeekDaysLong,
  periodArray,
} from './constants';
import { getOS } from './getMobileOS';
import { formatPrice } from './formatPrice';
import {
  formatDate,
  formatDateShortString,
  formatDateWithWeekDay,
  getFirstDayOfMonth,
  getLastDayOfMonth,
  getDayFromDaysAgo,
  formatDateWithHifen,
} from './formatDate';
import {
  formatMonthAndYear,
  formatFullMonthAndYear,
  formatDateTransitionYear,
  formatDateTransition,
  getWeek,
  formatDateHour,
  formatMonth,
  returnDay,
} from './formatMonthAndYear';
import { getFirstAndLastDateOfMonth, get8MonthsForward, get8MonthsAgo } from './getFirstAndLastDateOfMonth';
import { formatType } from './formatType';
import { isDateIntervalGreaterThan90Days } from './getIntervalDates';

import { ThemeQ2 } from '../constants';

export const isQ2Pay = (theme: ThemeQ2) => theme === ThemeQ2.q2pay;

export const changeFavicon = (src: string) => {
  const link = document.createElement('link');
  const oldLink = document.getElementById('dynamic-favicon');
  link.id = 'dynamic-favicon';
  link.rel = 'shortcut icon';
  link.href = src;
  if (oldLink) {
    document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
};

export {
  lumiance,
  APP_TOKEN,
  getOS,
  formatPrice,
  formatDate,
  Months,
  WeekDaysShort,
  WeekDaysLong,
  getFirstDayOfMonth,
  getLastDayOfMonth,
  formatDateShortString,
  formatDateWithWeekDay,
  getDayFromDaysAgo,
  periodArray,
  formatMonthAndYear,
  formatFullMonthAndYear,
  getFirstAndLastDateOfMonth,
  formatDateWithHifen,
  formatDateTransition,
  formatDateTransitionYear,
  getWeek,
  formatDateHour,
  formatType,
  formatMonth,
  returnDay,
  isDateIntervalGreaterThan90Days,
  get8MonthsForward,
  get8MonthsAgo,
};
