/* eslint-disable import/no-cycle */
import { colors, colorsQ2Pay, colorsUnimedPay } from './colors';

export const themeQ2Pay = {
  colors: colorsQ2Pay,
};

export const themeUnimedPay = {
  colors: colorsUnimedPay,
};

export { colors };
