import React, { useEffect } from 'react';
import sentAnimation from '../../assets/animations/mail-sent.json';
import { Props } from './types';
import {
  Container,
  CloseModal,
  Modal,
  Title,
  Text,
  SentEmailAnimation,
} from './styles';

const SentPasswordModal: React.FC<Props> = ({ show, setShow }) => {
  const handleDisableScroll = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (show) {
      window.addEventListener('scroll', handleDisableScroll);
      return () => {
        window.removeEventListener('scroll', handleDisableScroll);
      };
    }
    return () => {};
  }, [show]);

  return (
    <Container
      show={show}
      onClick={() => setShow(false)}
    >
      <Modal show={show} onClick={(e) => e.stopPropagation()}>
        <CloseModal onClick={() => setShow(false)} />
        <Title>Solicitação de senha</Title>
        <Text>
          Seus dados de acesso a Queridona Smart
          e a senha administrativa foram enviados
          para o e-mail cadastrado em sua conta,
          e em alguns minutos vai estar na sua caixa de entrada.
        </Text>
        <Text>
          Se não aparecer, dê uma olhadinha
          na caixa de SPAM também.
        </Text>
        <SentEmailAnimation
          isClickToPauseDisabled
          height={70}
          width={100}
          isStopped={!show}
          options={{
            autoplay: show,
            loop: true,
            animationData: sentAnimation,
          }}
        />
      </Modal>
    </Container>
  );
};

export default SentPasswordModal;
